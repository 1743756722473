.members-container.site-page-header .ant-page-header-heading-title {
  font-weight: 700;
  font-size: 18px;
}

.members-container td:has(div.spidertxt-disabled) {
  background-color: #FAFAFA;
  color: #BFBFBF;
}

#member-drawer-text-content {
  max-width: 250px;
  overflow-wrap: break-word;
}

#spiderTxtStatus.spidertxt-disabled > div.ant-select-selection {
  background-color: #FAFAFA;
  color: #BFBFBF;
}