.groups-container tr:has(td div.inactive) {
  background-color: #FAFAFA;
  color: #BFBFBF;
}

.groups-container td:has(span.no-members) {
  background-color: #fff1f0;
}

.groups-container td:has(div.spidertxt-disabled) {
  background-color: #FAFAFA;
  color: #BFBFBF;
}

#group-spidertxt-status.spidertxt-disabled {
  color: #BFBFBF;
}